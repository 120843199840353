import React from "react";


const Trending=()=>{
    return(
        <div>
            hello depuis trending
        </div>
    )
};

export default Trending;